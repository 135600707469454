import React from "react";
import { Row, Col, Modal, ModalBody, Button } from "reactstrap";
import {
  ErrorCircle,
  LoadingIcon,
  TimeoutIcon,
  WarningIcon,
} from "../../commons/components/Icons";

interface Props {
  errCode?: string;
  backClick: any;
  products_count: number;
  errorMessage: string;
  isPolling: boolean;
}

const anchorStyle = {
  color: "#0172CB",
};

class RedemptionError extends React.Component<Props> {
  getErrData = () => {
    const {
      errCode = "",
      products_count = 0,
      errorMessage = "",
      isPolling,
    } = this.props;
    let errData;
    switch (errCode) {
      case "LOW_BALANCE":
        errData = {
          icon: <WarningIcon />,
          title:
            "Error : #001 : The link is currently unavailable for redemption!",
          subTitle: (
            <span>
              Please check back in 24-48 hours or write to{" "}
              <a
                href="mailto:cs@xoxoday.com"
                target="_blank"
                style={anchorStyle}
              >
                cs@xoxoday.com
              </a>
            </span>
          ),
          backBtn: false,
        };
        break;
      case "PENDING":
        errData = {
          icon: <LoadingIcon />,
          title: "The order is being processed...",
          subTitle: (
            <span>
              Please check back in the same link after some time or write to{" "}
              <a
                href="mailto:cs@xoxoday.com"
                target="_blank"
                style={anchorStyle}
              >
                cs@xoxoday.com
              </a>
            </span>
          ),
          backBtn: false,
        };
        break;
      case "PRODUCT_NOT_FOUND":
        {
          if (products_count > 1) {
            return (errData = {
              icon: <WarningIcon />,
              title: "The product is currently unavailable for redemption!",
              subTitle: "Please try redeeming something else from the list.",
              backBtn: true,
            });
          } else {
            return (errData = {
              icon: <WarningIcon />,
              title: "The product is currently unavailable!",
              subTitle: (
                <span>
                  Please check back in the same link after some time or write to{" "}
                  <a
                    href="mailto:cs@xoxoday.com"
                    target="_blank"
                    style={anchorStyle}
                  >
                    cs@xoxoday.com
                  </a>
                </span>
              ),
              backBtn: false,
            });
          }
        }
        break;
      case "LINK_CANCELLED":
        errData = {
          icon: <ErrorCircle />,
          title: "This Xoxo Link has been cancelled by the sender!",
          subTitle: (
            <span>
              This is no longer available for redemption. Please write to{" "}
              <a
                href="mailto:cs@xoxoday.com"
                target="_blank"
                style={anchorStyle}
              >
                cs@xoxoday.com
              </a>{" "}
              for any queries.
            </span>
          ),
          backBtn: false,
        };
        break;
      case "LINK_EXPIRED":
        errData = {
          icon: <TimeoutIcon />,
          title: "This Xoxo Link is expired!",
          subTitle: "This is no longer available for redemption.",
          backBtn: false,
        };
        break;
      case "GV_LINK_FAILED":
        errData = {
          icon: <LoadingIcon />,
          title: errorMessage,
          subTitle: (
            <span>
              {isPolling ? "Refreshing status, please wait..." : null}
            </span>
          ),
          backBtn: false,
        };
        break;
      case "GV_LINK_CANCELLED":
        errData = {
          icon: <ErrorCircle />,
          title: errorMessage,
          subTitle: "",
          backBtn: false,
        };
        break;
      case "GV_LINK_EXPIRED":
        errData = {
          icon: <ErrorCircle />,
          title: errorMessage,
          subTitle: "",
          backBtn: false,
        };
        break;
      case "CrossCurrencyCode":
        errData = {
          icon: <ErrorCircle />,
          title: "Error in redeeming xoxo code.",
          subTitle: (
            <span>
              Uh oh! Something went wrong. But don't worry, please reach out to{" "}
              <a
                href="mailto:cs@xoxoday.com"
                target="_blank"
                style={anchorStyle}
              >
                cs@xoxoday.com
              </a>{" "}
              with this error code (ERFOREXLINKCODE-1). We got your back!
            </span>
          ),
          backBtn: false,
        };
        break;
      case "":
        errData = {
          icon: <LoadingIcon />,
          title: "",
          subTitle: "",
          backBtn: false,
        };
      default:
        errData = {
          icon: <ErrorCircle />,
          title: errCode,
          subTitle: "",
          backBtn: false,
        };
    }
    return errData;
  };

  renderErrorMsg = () => {
    const { errCode = "" } = this.props;
    const errData = this.getErrData() || {
      icon: <LoadingIcon />,
      title: "",
      subTitle: "",
      backBtn: false,
    };
    return (
      <div className="my-4">
        {errCode.length > 0 ? (
          <>
            <h1 className="mb-4">{errData.icon}</h1>
            <div className="fw-500 fs-16 mb-2 ">{errData.title}</div>
            <div>{errData.subTitle}</div>
            {errData.backBtn ? (
              <Button
                className="mt-4 back-to-vouchers"
                color="default"
                onClick={() => this.props.backClick()}
              >
                Back to vouchers
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <Modal isOpen={true} className="modal-wrapper error-modal">
        <ModalBody className="text-center modal-padding">
          <Row>
            <Col className="text-center">{this.renderErrorMsg()}</Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default RedemptionError;
