const config = require("../../config/config.production.json");

export const checkGivenEmailIsValid = (string) => {
  const str = string.trim();
  var reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(str);
};

export const checkGivenMobileNumberIsValid = (string) => {
  const str = string.trim();
  // var reg = /^\d{7,16}/;
  var reg = /^(\+\d{1,3}[- ]?)?\d{7,16}$/;
  return reg.test(str);
};

export const makeRequestAuth = async ({
  data,
  extra_headers = {},
  url = "",
  codeLength = 16,
}) => {
  if (!url) {
    url = config.epauth[codeLength] + "/api/public/graph";
  }
  if (!extra_headers) extra_headers = {};

  if (!data) {
    data = {};
  }

  if (data.tag) {
    url = url + "/" + data.tag; //`${url}/${data.tag}`
  }

  let headers = {
    "Content-Type": "application/json",
    pltfm: 4,
    lng: "en",
  };

  for (var i = 0; i < Object.keys(extra_headers).length; i++) {
    var key = Object.keys(extra_headers)[i];
    headers[key] = extra_headers[key];
  }

  if (data.query) {
    //for mod security
    data.query = data.query.replace(new RegExp("\n", "g"), "");
  }
  const endPoints = data.query ? data.query.split(".") : "";
  const subEndPoint = endPoints[2] || "";
  url = `${url}/${subEndPoint}`;

  try {
    console.log("url:", url);
    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers,
      credentials: "include",
    });
    return res.json();
  } catch (error) {
    return { error: 1, error_msg: error };
  }
};
