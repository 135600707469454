import React, { useState, useEffect, memo, useRef } from "react";
import "../../home.scss";
import ButtonLoader from "../../../../commons/components/Loader/ButtonLoader";
import OtpInput from "react-otp-input";
import { Row, Col, Input } from "reactstrap";
import Select, { components } from "react-select";
import { phone_codes } from "../../../../utils/locales";
import axios from "axios";
import { MOBILE_NO_ERROR } from "../../../../commons/constants/app.constants";
import SpecialRedemptionModal from "../SpecialRedemptionModal/SpecialRedemptionModal";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { Turnstile } from "@plum-xoxo/turnstile_captcha";
import {
  checkGivenEmailIsValid,
  checkGivenMobileNumberIsValid,
  makeRequestAuth,
} from "../../../../utils/globals";
import config from "../../../../../config/config.production.json";
const TEMPLATE_ID = "link_redemption_otp";
const SUBJECT = "OTP to access Xoxo Link reward";
const TURNSTILE_SITE_KEY = config.turnstileSiteKey;

interface PostParams {
  phone_code: string;
  phone: string;
  email: string;
  otp_id?: string;
  otp?: string;
  template_id?: string;
  subject?: string;
  token?: string;
}

const CollectVoucherCard = (props: any) => {
  const {
    delivery_method,
    linkCode,
    xc_selected,
    handleModal,
    selected,
    item,
    handleHtml,
    campaign,
    isUserVerified,
    redemption_check,
    customStyles,
    otpId,
    setOtpId,
    toggleTnc,
    verify_mobile = 0,
    setSelected = () => {},
  } = props;
  const [renderTurnstileCaptcha, toggleTurnstileCaptcha] = useState(false);
  const [renderResendTurnstileCaptcha, toggleResendTurnstileCaptcha] =
    useState(false);
  const [showSpecialRedemptionModal, setShowSpecialRedemptionModal] =
    useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableCollectVoucherBtn, setdisableCollectVoucherBtn] =
    useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isdCode, setIsdCode] = useState("+91");
  const [isdCodeObject, setIsdCodeObject] = useState<any>({
    country_id: 99,
    name: "India",
    phonecode: "+91",
    iso: "IN",
  });
  const [selectedUserInfo, setSelectedUserInfo] = useState<any>("email");
  const [phoneCodesUi, setPhoneCodesUi] = useState<any>([]);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otpValue, setOtpValue] = useState("");

  const [seconds, setSeconds] = useState(60);
  let intervalRef = useRef<any>();

  const decreaseNum = () => setSeconds((prev) => prev - 1);
  const otpInputRef = useRef<any>();

  useEffect(() => {
    if (otpId) {
      intervalRef.current = setInterval(decreaseNum, 1000);
      return () => clearInterval(intervalRef.current);
    }
  }, [otpId]);

  useEffect(() => {
    const selectedUserInfoUpdated =
      delivery_method === 2 || verify_mobile === 1 ? "phone" : "email";
    setSelectedUserInfo(selectedUserInfoUpdated);
  }, [delivery_method]);

  useEffect(() => {
    const phone_codes_ui =
      phone_codes &&
      phone_codes.map((item, index) => {
        let obj = item;
        obj.label = getUnicodeFlagIcon(item.iso) + " " + item.phonecode;
        obj.value = item.country_id;
        return obj;
      });

    setPhoneCodesUi(phone_codes_ui);
    otpInputRef.current &&
      otpInputRef.current.focus &&
      otpInputRef.current.focus();
  }, [otpId]);

  useEffect(() => {
    const phone_codes_ui =
      phone_codes &&
      phone_codes.map((item, index) => {
        let obj = item;
        obj.label = getUnicodeFlagIcon(item.iso) + " " + item.phonecode;
        obj.value = item.country_id;
        return obj;
      });
    getDefaultIsd(phone_codes_ui);
  }, []);

  useEffect(() => {
    if (email && email.length > 0) {
      const emailValidCheck = checkGivenEmailIsValid(email);
      emailValidCheck && setIsEmailValid(emailValidCheck);
      disableBtn && setDisableBtn(false);
    } else {
      !disableBtn && setDisableBtn(true);
    }
  }, [email]);
  useEffect(() => {
    if (phone && phone.length > 0) {
      const phoneValidCheck = checkGivenMobileNumberIsValid(phone);
      phoneValidCheck && setIsPhoneValid(phoneValidCheck);
      disableBtn && setDisableBtn(false);
    } else {
      !disableBtn && setDisableBtn(true);
    }
  }, [phone]);

  useEffect(() => {
    if (otpValue.length === 6) {
      disableCollectVoucherBtn && setdisableCollectVoucherBtn(false);
      handleSendOtp({});
    } else {
      !disableCollectVoucherBtn && setdisableCollectVoucherBtn(true);
    }
  }, [otpValue]);

  useEffect(() => {
    props.setShowSpecialRedemptionModal &&
      props.setShowSpecialRedemptionModal(showSpecialRedemptionModal);
  }, [showSpecialRedemptionModal]);

  const handleEmailChange = (event: any) => {
    let email = event.target.value;
    setEmail(email);
  };

  const handleValidateInput = (field: string = "both") => {
    if (!email && !phone) {
      setDisableBtn(true);
      return false;
    }
    let validEmail = isEmailValid;
    let validPhone = isPhoneValid;
    if ((field === "email" || field === "both") && email.length > 0) {
      validEmail = checkGivenEmailIsValid(email);
    }
    if ((field === "phone" || field === "both") && phone.length > 0) {
      validPhone = checkGivenMobileNumberIsValid(phone);
    }
    setIsEmailValid(validEmail);
    setIsPhoneValid(validPhone);
    if (field === "email" && !validEmail) {
      setDisableBtn(true);
      return false;
    } else if (field === "phone" && !validPhone) {
      setDisableBtn(true);
      setOtpError("");
      return false;
    } else {
      setDisableBtn(false);
      return true;
    }
  };

  const handleMobileChange = (event: any) => {
    // this.setState({
    // mobileNumber: event.target.value,
    // });
    if (event && event.target) {
      let mobileNumber = event.target.value || "";
      let reg = /^\d+$/;

      if (
        !(mobileNumber.length == 0 && mobileNumber == "") &&
        (!reg.test(mobileNumber) || parseInt(mobileNumber) == 0)
      ) {
        return;
      }
      mobileNumber.length <= 16 && setPhone(mobileNumber);
    }
  };

  const handleISDchange = (event: any) => {
    setIsdCode(event.phonecode);
    setIsdCodeObject(event);
    setError(false);
    setErrorMsg("");
  };

  const getDefaultIsd = async (phone_codes_ui: any) => {
    await axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let countryCode = "IN";
        if (response && response.status && response.status === 200) {
          countryCode = response.data.country_code;
        }
        const phonecodeDetails = phone_codes_ui.find(
          (item: any) => item.iso == countryCode
        );
        setIsdCode(phonecodeDetails.phonecode);
        setIsdCodeObject(phonecodeDetails);
      })
      .catch(() => {
        let countryCode = "IN";
        const phonecodeDetails = phone_codes_ui.find(
          (item: any) => item.iso == countryCode
        );
        setIsdCode(phonecodeDetails.phonecode);
        setIsdCodeObject(phonecodeDetails);
      });
  };

  const handleSendOtp = async ({ token = "", resend = false }) => {
    await setSelected(selected === 0 ? 1 : selected);
    if (disableBtn || (!email && !phone && !resend)) return;
    setLoading(true);

    let post_params: PostParams = {
      phone_code: isdCode,
      phone: phone,
      email: email,
      template_id: TEMPLATE_ID,
      subject: SUBJECT,
    };
    if (resend) {
      post_params.otp_id = otpId;
      post_params.token = token;
      setSeconds(60);
    } else if (otpId && otpValue) {
      post_params.otp_id = otpId;
      post_params.otp = otpValue;
    } else {
      post_params.token = token;
    }

    const query = "stores.mutation.plum_otp_service";

    const post_data = {
      query: query,
      tag: "stores",
      variables: {
        userData: JSON.stringify(post_params),
      },
    };

    const res = await makeRequestAuth({
      data: post_data,
      codeLength: linkCode.length,
    });

    if (res && res.data && res.data.plumOtpService) {
      toggleTurnstileCaptcha(false);
      toggleResendTurnstileCaptcha(false);
      const { plumOtpService } = res.data;
      if (plumOtpService.success === 1 && plumOtpService.otp_success === 1) {
        if (plumOtpService.validated) {
          if (verify_mobile) {
            setShowSpecialRedemptionModal(true);
            setLoading(false);
          } else {
            const initBtCl = true;
            xc_selected
              ? handleModal(
                  0,
                  xc_selected,
                  phone,
                  email,
                  isdCodeObject,
                  verify_mobile,
                  initBtCl
                )
              : handleModal(
                  selected - 1,
                  xc_selected,
                  phone,
                  email,
                  isdCodeObject,
                  verify_mobile,
                  initBtCl
                );
          }
        } else {
          setLoading(false);
          !resend && setOtpId(plumOtpService.otp_id);
        }
        setOtpSuccess(true);
        setOtpError("");
      } else {
        setLoading(false);
        setOtpError(plumOtpService.message);
        setOtpSuccess(false);
      }
    } else {
      setLoading(false);
      setOtpError("Something went wrong. Please try again later.");
      setOtpSuccess(false);
    }
  };

  const onChangeUserDetailsSelect = (e: any) => {
    setSelectedUserInfo(e.target.value);
    setEmail("");
    setPhone("");
  };

  const tncAndHowToUse = (
    <Col className=" tnc-link mt-12">
      <div onClick={toggleTnc} className="cursor-pointer">
        Terms & conditions
      </div>{" "}
      |{" "}
      <div onClick={toggleTnc} className="cursor-pointer">
        How to use
      </div>
    </Col>
  );

  const redemptionMessage = (
    <Col className=" tnc-link mt-12 no-pointer">
      <div>Cancellation is not applicable once this voucher is issued.</div>
    </Col>
  );

  const renderOtp = () => (
    <>
      <Row className="mb-4">
        <Col lg="1">
          <a
            onClick={() => {
              setOtpId("");
              setOtpValue("");
              setOtpError("");
              setSeconds(60);
            }}
          >
            <img src="/img/icons/back-arrow.svg" alt="backBtnIcon" />
          </a>
        </Col>
        <Col lg="11">
          {phone ? (
            <>
              <Row>
                <Col className="h6 text-black">Verify your phone number</Col>
              </Row>
              <Row>
                <Col className="text-color-ink-light">
                  Enter the OTP sent to the phone number {isdCode} - {phone}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col className="h6 text-black">Verify your email address</Col>
              </Row>
              <Row>
                <Col className="text-color-ink-light">
                  Enter the OTP we just sent to the email address {email}
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row className="text-center mt-4">
        <Col className="column-center">
          <OtpInput
            ref={otpInputRef}
            shouldAutoFocus={true}
            value={otpValue}
            onChange={setOtpValue}
            numInputs={6}
            separator={
              <span>
                <strong> </strong>
              </span>
            }
            inputStyle={{
              width: "3rem",
              height: "3rem",
              margin: "0 0.2rem",
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "16px",
              borderRadius: "4px",
              color: "#000",
              border: "1px solid #BAC7D5",
            }}
            className="column-center"
          />
        </Col>
      </Row>
      <Row className="text-center mt-2">
        <Col className="text-error">
          {otpError ? "OTP does not match" : null}
        </Col>
      </Row>
      <Row className="text-center mt-4">
        <Col>
          {seconds > 0 && seconds < 60 ? (
            <div className="text-color-ink-light">
              Resend OTP in{" "}
              <span className="exclude-translation">{seconds}</span> second(s)
            </div>
          ) : renderResendTurnstileCaptcha ? (
            <Turnstile
              className="text-center"
              siteKey={TURNSTILE_SITE_KEY}
              onSuccess={(token: any) => handleSendOtp({ token, resend: true })}
              options={{ theme: "light" }}
              style={{ width: "100%" }}
            />
          ) : (
            <a
              onClick={() => toggleResendTurnstileCaptcha(true)}
              className="blue-link"
            >
              Resend OTP
            </a>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <button
            className="btn btn-filled mt-2"
            onClick={() => handleSendOtp({ resend: false })}
            disabled={disableCollectVoucherBtn || loading}
          >
            {loading ? (
              <ButtonLoader />
            ) : verify_mobile === 1 ? (
              "Verify OTP"
            ) : (
              "Collect Voucher"
            )}
          </button>
        </Col>
      </Row>

      <Row>{redemptionMessage}</Row>
      <Row>{tncAndHowToUse}</Row>
    </>
  );
  const CaretDownIcon = () => {
    return <img src="/img/icons/vector.svg" alt="caret-down" />;
  };
  const collectVoucher = () => {
    const initBtCl = true;
    xc_selected
      ? handleModal(
          0,
          xc_selected,
          phone,
          email,
          isdCodeObject,
          verify_mobile,
          initBtCl
        )
      : handleModal(
          selected - 1,
          false,
          phone,
          email,
          isdCodeObject,
          verify_mobile,
          initBtCl
        );
  };

  const cancelVoucher = () => {
    setOtpId("");
    setOtpValue("");
    setOtpError("");
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return showSpecialRedemptionModal ? (
    <SpecialRedemptionModal
      showModal={showSpecialRedemptionModal}
      setShowModal={setShowSpecialRedemptionModal}
      collectVoucher={collectVoucher}
      cancelVoucher={cancelVoucher}
      itemName={item.name}
    />
  ) : (
    <div>
      {!otpId ? (
        <Row>
          <Col lg="4" xs="12">
            <div className="card_logo">
              {/* <img src={CLOUDINARY_URL + item.logo} alt='logo' /> */}
              <img className="img-fluid" src={item.image} alt="logo" />
            </div>
          </Col>
          <Col lg="8" xs="12">
            <div className="selected-title">{item.name ? item.name : ""}</div>
            <div>
              {item.expiry_and_validity ? (
                <div className="validity">
                  <strong>
                    {handleHtml(item.expiry_and_validity)} validity
                  </strong>
                </div>
              ) : null}
              {campaign ? (
                <h2>
                  {campaign.currency_code ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: campaign.currency_code,
                      }}
                    ></span>
                  ) : (
                    ""
                  )}{" "}
                  {campaign.amount ? campaign.amount : ""}
                </h2>
              ) : null}
              {item.description ? (
                <div className="description">
                  {/* {item.description} */}
                  This gift voucher can be used on the website at checkout or in
                  the store as per the terms and conditions.
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      ) : (
        renderOtp()
      )}

      {otpId ? null : (!isUserVerified && redemption_check) ||
        verify_mobile === 1 ? (
        <>
          <hr className="mt-4 mb-4" />
          <Row>
            <Col className="enter-info-discription">
              Enter your information to collect voucher
            </Col>
          </Row>
          {verify_mobile === 0 && !isUserVerified && redemption_check && (
            <>
              <Row
                className="radio-inputs"
                onChange={onChangeUserDetailsSelect}
              >
                <Col xs="6" lg="3" className="radio-buttons">
                  <input
                    type="radio"
                    value="email"
                    name="userinfo"
                    title="Email"
                    placeholder="Email address"
                    checked={selectedUserInfo === "email"}
                  />{" "}
                  <label className="label">
                    <span>Email address </span>
                  </label>
                </Col>

                <Col xs="6" lg="3" className="radio-buttons">
                  <input
                    type="radio"
                    value="phone"
                    name="userinfo"
                    title="Phone"
                    placeholder="Phone number"
                    checked={selectedUserInfo === "phone"}
                    maxLength={16}
                  />{" "}
                  <label className="label">
                    <span>Phone number </span>
                  </label>
                </Col>
              </Row>
            </>
          )}

          {selectedUserInfo === "email" ? (
            <>
              <Row>
                <Col className="my-2 voucher__input_block" lg="12">
                  <div className="voucher__input">
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={() => handleValidateInput("email")}
                      placeholder="Email address"
                      id="email"
                      className={
                        !isEmailValid
                          ? "input-send-copy inputErrorOnly  error"
                          : "input-send-copy"
                      }
                    />
                  </div>
                </Col>
              </Row>

              {!isEmailValid && !loading ? (
                <Row>
                  <Col lg="12">
                    <p className="-phnError mb-1 py-0">
                      Please enter a valid email address
                    </p>
                  </Col>
                </Row>
              ) : null}
            </>
          ) : (
            <>
              <Row>
                <Col className="my-2 voucher__input_block" lg="12">
                  <div className="voucher__input d-flex">
                    <Select
                      options={phoneCodesUi}
                      value={isdCodeObject}
                      isSearchable={true}
                      onChange={(event) => {
                        handleISDchange(event);
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                      }}
                      placeholder=""
                      // placeholder="+91"
                      noOptionsMessage={() => {
                        return "No Code";
                      }}
                      className="react-select -countryCode mr-2"
                      styles={customStyles}
                      // defaultValue={phone_codes[99].phonecode}
                    />
                    {/* type="number" */}
                    <Input
                      placeholder="Phone number"
                      onChange={handleMobileChange}
                      onBlur={() => handleValidateInput("phone")}
                      value={phone}
                      className={
                        !isPhoneValid
                          ? "inputErrorOnly error"
                          : "inputErrorOnly"
                      }
                    />
                  </div>
                  <div className="d-flex">
                    <div className="-countryCode mr-2 d-none d-md-block"></div>
                    <div className="w-100">
                      {!isPhoneValid && !loading && !otpError ? (
                        <Row>
                          <Col lg="12">
                            <p className="-phnError mb-1 py-0">
                              {MOBILE_NO_ERROR}
                            </p>
                          </Col>
                        </Row>
                      ) : null}
                      {otpError ? (
                        <Row className="">
                          <Col className="text-error">{otpError}</Col>
                        </Row>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row className="mt-4 mb-2">
            <Col>
              {renderTurnstileCaptcha ? (
                <Turnstile
                  className="text-center"
                  siteKey={TURNSTILE_SITE_KEY}
                  onSuccess={(token: any) => handleSendOtp({ token })}
                  options={{ theme: "light" }}
                  style={{ width: "100%" }}
                />
              ) : (
                <button
                  className="btn btn-filled mt-2"
                  disabled={disableBtn}
                  onClick={() => toggleTurnstileCaptcha(true)}
                >
                  {loading ? <ButtonLoader /> : "Send OTP"}
                </button>
              )}
            </Col>
          </Row>
          <Row>{tncAndHowToUse}</Row>
        </>
      ) : (
        <>
          <Row className="mt-4 mb-2">
            <Col>
              <button
                className="btn btn-filled mt-2"
                disabled={loading}
                onClick={(e) => {
                  setLoading(true);
                  e.preventDefault();
                  if (verify_mobile) {
                    setShowSpecialRedemptionModal(true);
                  } else {
                    const initBtCl = true;
                    xc_selected
                      ? handleModal(
                          0,
                          xc_selected,
                          phone,
                          email,
                          isdCodeObject,
                          verify_mobile,
                          initBtCl
                        )
                      : handleModal(
                          selected - 1,
                          false,
                          phone,
                          email,
                          isdCodeObject,
                          verify_mobile,
                          initBtCl
                        );
                  }
                }}
              >
                {loading ? <ButtonLoader /> : "Collect Voucher"}
              </button>
            </Col>
          </Row>
          <Row>{redemptionMessage}</Row>
          <Row>{tncAndHowToUse}</Row>
        </>
      )}
    </div>
  );
};

export default CollectVoucherCard;
