import React, { useState, useEffect } from "react";
import "./verify-mobile.scss";
import { Row, Col, Modal, ModalBody, Button } from "reactstrap";
import TncBack from "../Cards/TncBack";

const VerifyMobile = ({ product = {}, phone, errCode }: any) => {
  const [showTnc, setShowTnc] = useState(false);
  const [showPopup, setPopup] = useState(false);
  const toggleTnc = () => {
    setShowTnc((showTnc) => !showTnc);
  };

  const pendingText = {
    title: "🎉 Thank you for placing the order!",
    subtitle: `The Reward value will be added to the ${product.name} wallet on the phone number provided. Please refresh the link after sometime to view the latest status`,
  };

  const processedText = {
    title: "Order processed!",
    subtitle: `The ${product.name} order has been processed and the funds have been added to <br /> ${phone} For any further queries, <br /> please email <a href="mailto:cs@xoxoday.com" target="_blank">cs@xoxoday.com</a>`,
  };

  const text = errCode === "" ? processedText : pendingText;

  useEffect(() => {
    setPopup(true);
  }, []);

  return (
    <>
      {/* <meta http-equiv="refresh" content="30" /> */}
      {showTnc ? (
        <TncBack showTnc={showTnc} toggleTnc={toggleTnc} data={product} />
      ) : (
        <Modal
          isOpen={showPopup}
          backdrop={true}
          fade={false}
          className="verify-mobile"
        >
          <ModalBody className="text-center modal-padding">
            <Row>
              <Col className="text-center">
                <div className="verify-mobile_title">{text.title}</div>
                <div
                  className="verify-mobile_subtitle"
                  dangerouslySetInnerHTML={{ __html: text.subtitle }}
                />
                <div className="verify-mobile_tnc" onClick={toggleTnc}>
                  Terms & Conditions | How to use
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default VerifyMobile;
