import React, { useState } from "react";
// import { CLOUDINARY_URL } from '../../../commons/constants'
import { Navbar, NavbarBrand, NavbarText, Nav, NavItem } from "reactstrap";
import LanguageSwitchPopup from "./LanguageSwitchPopup";

const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;

interface HeaderProps {
  company_logo?: string;
  linkCode?: string;
  currencyCode?: string;
  amount?: number;
  scrolled: boolean;
  showHeader?: boolean;
  campaign?: any;
  onlyXoxoCode: boolean;
  isMultiLangSupported: boolean;
}

const Header: React.FC<HeaderProps> = ({
  company_logo,
  linkCode,
  currencyCode = "",
  amount = 0,
  scrolled = false,
  showHeader = false,
  campaign,
  onlyXoxoCode = false,
  isMultiLangSupported = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogo, setIsLogo] = useState(false);

  // let logo = company_logo && company_logo.includes('amazonaws') ? company_logo : company_logo

  let logo = company_logo
    ? company_logo
    : "https://res.cloudinary.com/dyyjph6kx/image/upload/v1625579804/store/Logo/plum-logo.svg";

  const toggle = () => setIsOpen(!isOpen);
  const toggleLogo = () => setIsLogo(!isLogo);

  if (logo && !isLogo) {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    if (favicon && favicon.href) {
      favicon.href = `${logo}`;
    }
  }
  // ${ showHeader ? 'vshow' : 'vhide' }

  const convertUnicode = (input: string) => {
    return input.replace(/\\u([0-9a-fA-F]{4})/g, function (a, b) {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  };

  function isHexLight(color: string = "") {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  }

  return (
    <>
      <Navbar
        light
        expand="md"
        className={`justify-content-between ${
          isMultiLangSupported ? "" : "navbar-nonmultilang"
        } ${scrolled ? (onlyXoxoCode ? "bg-white" : "scrolled") : ""}`}
      >
        <NavbarBrand href={`/${linkCode}`}>
          {logo && !isLogo ? (
            <img src={logo} alt="logo" onError={toggleLogo} />
          ) : null}
        </NavbarBrand>
        <div
          className={`navbar-menu
            ${
              isHexLight(campaign.backgroundColor)
                ? " navbar-menu-dark"
                : " navbar-menu-light"
            }`}
        >
          {isMultiLangSupported && (
            <LanguageSwitchPopup onlyXoxoCode={onlyXoxoCode} />
          )}
          <div
            className={
              onlyXoxoCode
                ? "navbar-options navbar-options-onlyxoxocode"
                : "navbar-options"
            }
          >
            <span>Your Reward:</span>{" "}
            <span>
              {campaign && campaign.currency_code} {campaign && campaign.amount}
            </span>
          </div>
        </div>
      </Navbar>
      {/* <div
        className="header-line"
        style={{ borderColor: campaign.backgroundColor }}
      ></div> */}
    </>
  );
};

export default Header;
