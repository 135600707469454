import axios from "axios";
import BaseApi from "./baseApi.service";

const node_server = process.env.REACT_APP_NODE_SERVER;
const captchaBaseUrl = process.env.REACT_APP_PLUM_GIFT;
class Api extends BaseApi {
  fetchVouchers = async (variables = {}) => {
    const url = node_server + "/vouchers/fetch";
    try {
      const { data } = await axios(url, {
        method: "POST",
        data: { code: variables },
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  linkStatus = async (variables = {}) => {
    const url = node_server + "/links/status";
    try {
      const { data } = await axios(url, {
        method: "POST",
        data: { code: variables },
      });

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  linkRedeem = async (variables = {}) => {
    const url = node_server + "/links/redeem";
    let payload = JSON.stringify(variables);

    try {
      const { data } = await axios(url, {
        method: "POST",
        data: variables,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  verifyResponseID = async (variables = {}, url = "") => {
    const urlObj = node_server + url;
    let payload = JSON.stringify(variables);

    try {
      const { data } = await axios(urlObj, {
        method: "POST",
        data: variables,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  claimReward = async (variables = {}, url = "") => {
    const urlObj = node_server + url;
    let payload = JSON.stringify(variables);

    try {
      const { data } = await axios(urlObj, {
        method: "POST",
        data: variables,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  sendOtp = async (variables = {}, codeLength = 16) => {
    const url = node_server + "/sendOtp";
    const payload_data = { ...variables, codeLength: codeLength };
    try {
      const { data } = await axios(url, {
        method: "POST",
        data: payload_data,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //http://localhost:4003/integration/qualtricsresponse/validateResponse
}

const ApiService = new Api();
Object.freeze(ApiService);

export default ApiService;
